<template>
    <section class="shop-delivery">
        <tool-bar v-bind:lang.sync="lang">
            Delivery Classes
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-on:click="$router.push('/shop/delivery/new')">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a delivery class</span>
                    </button>
                </div>

                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <section class="shop-delivery-content">
            <article class="message is-dark">
                <div class="message-body"
                     style="background-color: rgba(255, 255, 255, 1)">
                    <label class="label">
                        Delivery Classes
                    </label>
                    <p>
                        This option controls the delivery classes available to a customer.
                    </p>
                </div>
            </article>

            <div class="container card p-4">
                <draggable v-model="deliveryClasses"
                           v-on:end="onDragEnd">
                    <article v-for="{ _id, title, conditions, methods, } in deliveryClasses"
                             v-bind:key="_id">
                        <h5>
                            {{ title[lang] }}
                        </h5>
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th scope="col">
                                    Delivery Method
                                </th>
                                <th scope="col">
                                    <span v-if="conditions[0].sign === '>' && conditions[0].includeEqual">
                                        Purchase less than HK${{ conditions[0].value }}
                                    </span>
                                    <span v-if="conditions[0].sign === '>' && !conditions[0].includeEqual">
                                        Purchase HK${{ conditions[0].value }} or less
                                    </span>
                                </th>
                                <th scope="col">
                                    <span v-if="conditions[0].sign === '>' && conditions[0].includeEqual">
                                        Purchase HK${{ conditions[0].value }} or above
                                    </span>
                                    <span v-if="conditions[0].sign === '>' && !conditions[0].includeEqual">
                                        Purchase more than HK${{ conditions[0].value }}
                                    </span>
                                </th>
                                <th scope="col">
                                    Delivery Time (Working Days)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="{ name, price, deliveryTime, } in methods">
                                <td>{{ name[lang] }}</td>
                                <td>
                                    <span>HK${{ price.value }}</span>
                                </td>
                                <td>Free</td>
                                <td>{{ deliveryTime.from }}-{{ deliveryTime.to }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </article>
                </draggable>
            </div>
        </section>
    </section>
</template>

<script>
import ToolBar from "../layouts/ToolBar";
import draggable from "vuedraggable";

export default {
    name: "DeliveryClasses",
    components: {
        ToolBar,
        draggable
    },
    data() {
        return {
            lang: "en",
            isLoading: false,
            edited: false,

            deliveryClasses: []
        }
    },
    computed: {
        allowSave() {
            return this.edited
        },
    },
    methods: {
        onDragEnd() {
            this.edited = true
        },

        async save() {
            this.isLoading = true
            try {
                await this.$store.dispatch("shop/updateShop", {
                    "deliveryClasses": this.deliveryClasses
                })
            } catch (e) {
                console.error(e)
                this.isLoading = false
            }
            this.isLoading = false
        }
    },
    mounted() {
        this.deliveryClasses = this.$store.state.shop.deliveryClasses;
    },
    watch: {
        "$store.state.shop.deliveryClasses": {
            handler (newVal) {
                if (Array.isArray(newVal)) {
                    this.deliveryClasses = newVal;
                }
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.shop-delivery {
    & > .shop-delivery-content {
        $theme-colors: (
            "dark": rgba(113, 113, 113, 1)
        );
        @import "~bootstrap/scss/bootstrap";

        padding: 1rem;
        overflow-y: auto;

        .message {

        }
    }
}
</style>
